import {Vector3, Mesh, SphereGeometry, MeshStandardMaterial, Line, BufferGeometry, LineBasicMaterial, DoubleSide, MeshBasicMaterial, PointLight} from './three/three.js';
/**
 * A signle planet
 */
class Planet extends Mesh {
    /**
     * Construct a planet
     * @param {string} name name of the planet
     * @param {Vector3} position where the planet starts
     * @param {number} radius radius of the planet, purely visual
     * @param {number} mass mass of the planet in unspecified units
     * @param {MeshStandardMaterialParameters} materialSettings settings for the material of the planet 
     * @param {Vector3} velocity starting velocity
     */
    constructor(name, position, radius, mass, materialSettings, velocity) {
        super(new SphereGeometry(radius), new MeshStandardMaterial(materialSettings));
        this.position.set(position.x, position.y, position.z);
        this.receiveShadow = true;
        this.castShadow = true;
        this.userData = {type: 'planet'};
        this.mass = mass;
        this.velocity = velocity;
        this.name = name;
        this.trail = new Line(new BufferGeometry(), new LineBasicMaterial({color: materialSettings.color || materialSettings.trailColor || 0xffffff, side: DoubleSide, linewidth: 2}));
        this.trail.frustumCulled = false // prevent line from disappearing
        this.trailPoints = [];
    }
    /**
     * Add a force in a direction to the planet
     * @param {number} force the force amount
     * @param {Vector3} direction the normalized direction to apply the force in
     */
    addForceInDirection(force, direction) {
        //console.log("addForceInDirection", force, direction, this.mass, this.velocity)
        this.velocity.add(direction.clone().multiplyScalar(force / this.mass));
    }
    /**
     * Move the planet according to its velocity
     * Also updates the trail
     */
    doStep() {
        //3console.log("doStep", this.id, this.velocity, this.position)
        //debugger
        this.position.add(this.velocity.clone().multiplyScalar(0.1));
        this.trailPoints.push(this.position.clone());
        if (this.trailPoints.length > 2047) {
            this.trailPoints.shift();
        }
        this.trail.geometry.setFromPoints(this.trailPoints);
    }

}

/**
 * A sun that emits light
 */

class Sun extends Planet {
    constructor(name, position, radius, mass, materialSettings, velocity) {
        super(name, position, radius, mass, materialSettings, velocity);
        this.material = new MeshBasicMaterial(materialSettings);
        this.receiveShadow = false;
        this.castShadow = false; 
        this.attach(new PointLight( 0xffffff, 500 ));
    }
}

/**
 *  Scan a scene and return the ids of the planets in the scene
 * only returns top level items
 * @param {Scene} scene scene to scan
 * @returns {number[]} ids of the planets in the scene
 */
export function sceneListPlanets(scene) {
    let out = [];
    scene.traverse((child) => {
        if (child.userData.type === 'planet') {
            out.push(child.id);
        }
    })
    return out
}

export { Planet, Sun };
