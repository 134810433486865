import { Planet, Sun } from "./planet.mjs";
import {Vector3} from './three/three.js';
/**
 * just a list of planets
 * @param {any} tex textures
 * @returns {Planet[]} list of planets
 */
export default function(tex) {
    return [
        new Sun("Sun",                   new Vector3(0, 0, 0),  2,   1000, {map: tex.sun},    new Vector3(0, 0, 0)),
        new Planet("Green Planet",       new Vector3(0, 0, 5),  0.5, 1,    {color: 0x00ff00}, new Vector3(.35, 0, 0)),
        new Planet("Earth???",           new Vector3(15, 0, 0), 0.5, 1,    {map: tex.earth},  new Vector3(0, 0, .20)),
        new Planet("Red Planet",         new Vector3(25, 0, 0), 1.5, 4,    {color: 0xff0000}, new Vector3(0, 0, .15)),
        new Planet("Red Planet's moon",  new Vector3(21, 0, 0), 0.4, 2,    {color: 0xcccccc}, new Vector3(0, 0, .20)),
        new Planet("Dog planet",         new Vector3(0, 0, 15), 0.3, 1,    {map: tex.dog},    new Vector3(0, .20, 0))
    ]
}
