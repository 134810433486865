import { Scene, Texture, WebGLRenderer, PerspectiveCamera, PCFSoftShadowMap, AmbientLight, Vector3, Vector2, Raycaster } from './three/three.js';
import { OrbitControls } from './three/addons/controls/OrbitControls.js';
import Stats from './three/addons/libs/stats.module.js';
import { Planet, Sun, sceneListPlanets } from './planet.mjs';
import planets from './planets.mjs';
import { loadTextures } from './textures.mjs';

// USED RESOURCES:
// Github Copilot
// https://www.youtube.com/watch?v=7axImc1sxa0 inspiration for this project (very cool youtube channel, his atmosphere video is amazing)
// three.js documentation
// my head
// vite (for development)

/**
 * The element that contains the position of the focused planet
 */
const planet_focus_pos = document.querySelector('#planet_focus_pos');
/**
 * The element that contains the name of the focused planet
 */
const planet_focus_name = document.querySelector('#planet_focus_name');
/**
 * The element that contains the velocity of the focused planet
 */
const planet_focus_vel = document.querySelector('#planet_focus_vel');
/**
 * When the last update to the TPS counter was
 */
let lastTPSUpdate = performance.now();
/**
 * The number of ticks since the last TPS counter update
 */
let ticks = 0;
/**
 * The last time we ticked the simulation (run one step)
 */
let lastTick = performance.now();
/**
 * The object id of the planet we are currently looking at
 */
let targeted_planet = -1;
/**
 * The object id of the planet we were previously looking at
 */
let prev_targeted_planet = -1;
/**
 * The tween value for the camera position. 0 = looking at the previous planet, 1 = looking at the current planet
 */
let looking_tween = 1;


/**
 * Updates the on screen overaly
 * @param {Scene} scene the scene.
 * @param {Stats.Panel} tps_panel 
 */
function updateHUD(scene, tps_panel) {
    const planet = scene.getObjectById(targeted_planet);
    planet_focus_name.innerHTML = planet.name;
    planet_focus_pos.innerHTML = planet.position.x.toFixed(2) + ", " + planet.position.y.toFixed(2) + ", " + planet.position.z.toFixed(2);
    planet_focus_vel.innerHTML = planet.velocity.x.toFixed(2) + ", " + planet.velocity.y.toFixed(2) + ", " + planet.velocity.z.toFixed(2);
    
    if (performance.now() > lastTPSUpdate + 1000) {
        tps_panel.update(( ticks * 1000 ) / ( performance.now() - lastTPSUpdate ))
        ticks = 0;
        lastTPSUpdate = performance.now();
    }
}

/**
 * 
 * @param {Record<string, Texture>} textures the loaded textures from textures.mjs
 * @returns {{scene: Scene, renderer: WebGLRenderer, controls: any, camera: PerspectiveCamera, orbit_camera: PerspectiveCamera, stats: Stats, tps_panel: Stats.Panel}} everyting you could ever want.
 */
function createSceneNOther(textures) {

    let scene = new Scene();
    scene.background = textures.background;
    // camera stuff
    let camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    let renderer = new WebGLRenderer({ antialias: true });
    // workaround for planet targeting from stackoverflow (https://stackoverflow.com/a/53298655)
    // orbit camera always orbits around 0 0 0, so we can just add the position of the planet we are tracking to the camera position
    let orbit_camera = camera.clone();
    renderer.shadowMap.enabled = true; // turn on shadows
    renderer.shadowMap.type = PCFSoftShadowMap; // soft shadows
    renderer.setSize( window.innerWidth, window.innerHeight );
    document.body.appendChild( renderer.domElement );
    let controls = new OrbitControls(orbit_camera, renderer.domElement);
    controls.enablePan = false;
    orbit_camera.position.set(0, 10, 10);
    orbit_camera.lookAt(0, 0, 0);
    controls.update();

    // ambient light
    let ambientLight = new AmbientLight(0xffffff, 0.4);
    scene.add(ambientLight);


    // stats
    let stats = new Stats();
    document.body.appendChild(stats.dom);
    let tps_panel = new Stats.Panel("TPS", "#fff", "#000");
    document.body.appendChild(tps_panel.dom);
    // place the tps panel right below the fps one
    tps_panel.dom.style.top = "48px";
    tps_panel.dom.style.left = "0px";
    tps_panel.dom.style.position = "absolute";
    return {scene, renderer, controls, camera, orbit_camera, stats, tps_panel: tps_panel };

}





/**
 * does a single simuation step
 * @param {Scene} scene scene to update
 */
function doSimUpdate(scene) {
    for (const planet_id of sceneListPlanets(scene)) {
        /** @type {Planet} */
        const planet = scene.getObjectById(planet_id);
        for (const other_planets of sceneListPlanets(scene)) {
            if (other_planets === planet_id) continue;
            /** @type {Planet} */
            // for every planet pair (that isnt the same planet), calculate the force between them
            const other_planet = scene.getObjectById(other_planets);
            const dist = planet.position.distanceTo(other_planet.position);
            const force = 6.674 * 10**-5 * planet.mass * other_planet.mass / dist ** 2;
            const direction = new Vector3().subVectors(other_planet.position, planet.position).normalize();
            // apply the force to that planet
            planet.addForceInDirection(force, direction);
        }
    }
    for (const planet_id of sceneListPlanets(scene)) {
        /** @type {Planet} */
        const planet = scene.getObjectById(planet_id);
        //console.log(planet.velocity)
        // this moves the planet using the velocity
        planet.doStep();
    }
}


/**
 * Uses math to calculate how many steps we should simulate
 * tries to maintain 60 tps but somehow only does 42-45???
 * pretty sure its haunted
 * @param {Scene} scene scene to update
 */
function tryDoSimUpdate(scene) {
    let delta = performance.now() - lastTick;
    let ticks_this_frame = 0;
    while (delta >= 16.6) {
        doSimUpdate(scene);
        ticks += 1
        delta -= 16.6;
        lastTick = performance.now();
        ticks_this_frame += 1;
        // we don't want to simulate too many ticks in one frame
        // because that lags the browser
        // so we stop once we reach 100 and then resume on the next frame
        if (ticks_this_frame > 100) {
            console.warn("Skipping too many ticks, something is wrong")
            // we don't want to simulate too many ticks in one frame
            break;
        }
    }
}

/**
 * Switches to watching an certain object id
 * can be something other then a planet, but why would you do that?
 * @param {number} id object id of the planet to switch to 
 */

function switchToPlanet(id) {
    prev_targeted_planet = targeted_planet;
    targeted_planet = id;
    looking_tween = 0;
    //console.log("switching to planet", id)
}

/**
 * Does a single frame, simulating and rendering.
 * @param {{scene: Scene, renderer: WebGLRenderer, controls: any, camera: PerspectiveCamera, orbit_camera: PerspectiveCamera, stats: Stats}} param0 the scene and other stuff
 */
function doFrame({scene, renderer, controls, camera, orbit_camera, stats, tps_panel}) {
    // first, update the sim
    //console.log(dt)
    stats.begin();
    tryDoSimUpdate(scene);
    
    // update the hud
    updateHUD(scene, tps_panel);
    // then, update the camera position
    camera.copy(orbit_camera);
    const beforeTarget = new Vector3().copy(scene.getObjectById(prev_targeted_planet).position);
    const afterTarget = new Vector3().copy(scene.getObjectById(targeted_planet).position);
    const tweened = new Vector3().lerpVectors(beforeTarget, afterTarget, looking_tween);
    camera.position.add(tweened);
    //console.log(camera);
    // then, render the scene
    //console.log("render!")
    doRender(scene, renderer, camera);
    stats.end();
}

/**
 * Helper function to do a single render
 * maybe ill add more here?
 * @param {Scene} scene Scene to render
 * @param {WebGLRenderTarget} renderer 
 * @param {PerspectiveCamera} camera 
 */
function doRender(scene, renderer, camera) {
    renderer.render(scene, camera);
}

/**
 * The main function
 */
async function main() {
    console.log("Loading textures")
    let textures = await loadTextures();
    console.log("Loaded textures: ", textures)
    // create the scene
    let sceneNOther = createSceneNOther(textures);
    
    // create the planets
    for (const planet of planets(textures)) {
        sceneNOther.scene.add(planet);
        // we need to add the trail here instead of in the planet constructor
        // because if we add it in the planet constructor, the positions would be relative to the planet and not world cords.
        sceneNOther.scene.add(planet.trail); 
    }

    // we cant know these before we populate the scene.
    targeted_planet = sceneListPlanets(sceneNOther.scene)[0];
    prev_targeted_planet = sceneListPlanets(sceneNOther.scene)[0];
    // lets us access the scene and the other stuff from the console
    window.game_debug = sceneNOther;

    // events
    // click event: lets us move between planets
    sceneNOther.renderer.domElement.addEventListener('click', (evt) => {
        let raycaster = new Raycaster();
        let mouse = new Vector2();
        mouse.x = ( evt.clientX / window.innerWidth ) * 2 - 1;
        mouse.y = - ( evt.clientY / window.innerHeight ) * 2 + 1;
        raycaster.setFromCamera( mouse, sceneNOther.camera );
        const intersects = raycaster.intersectObjects(sceneNOther.scene.children);
        if (intersects.length > 0) {
            // switch to looking at it
            if (intersects[0].object.userData.type === 'planet') {
                switchToPlanet(intersects[0].object.id);

            }
            
        }
    
    })
    // lets us use the number keys to switch between planets
    window.addEventListener('keydown', (evt) => {
        //console.log(evt.key)
        if (parseInt(evt.key) <= sceneListPlanets(sceneNOther.scene).length && parseInt(evt.key) > 0) {
            prev_targeted_planet = targeted_planet;
            targeted_planet = sceneListPlanets(sceneNOther.scene)[parseInt(evt.key)-1];
            looking_tween = 0;
        } 
    
    })
    // prevents the window/camera from being weird when resizing
    window.addEventListener('resize', () => {
        sceneNOther.camera.aspect = window.innerWidth / window.innerHeight;
        sceneNOther.orbit_camera.aspect = window.innerWidth / window.innerHeight;
        sceneNOther.camera.updateProjectionMatrix();
        sceneNOther.orbit_camera.updateProjectionMatrix();
        sceneNOther.renderer.setSize(window.innerWidth, window.innerHeight);
        doRender(sceneNOther.scene, sceneNOther.renderer, sceneNOther.camera);
    })

    let prev_time = 0;
    function run(time) {
        // calculate deltatime in ms
        let dt = time - prev_time;
        if (looking_tween < 1) {
            // the tween always takes 1 second, thanks deltatime!
            looking_tween += dt/1000;
        }
        doFrame(sceneNOther);
        prev_time = time;
        requestAnimationFrame(run);
    }

    run(performance.now());
}

window.addEventListener('load', main);