import {Texture, TextureLoader, CubeTextureLoader} from './three/three.js';
/**
 * Loads all of the needed textures
 * @returns {Promise<Record<string, Texture>>} textures
 */
export async function loadTextures() {
    // The whole "new URL" thing is to make vite (the bundler i'm using for dev) happy (able to bundle the images properly)
    // it also lets vite do hashing and stuff so the images are cached properly
    // it doesn't affect just viewing the html file in a browser/static site, so it's fine
    const tloader = new TextureLoader();
    let texs = await Promise.all([
        // Dog planet
        tloader.loadAsync(new URL("../textures/dog.webp", import.meta.url)),
        // Sun
        tloader.loadAsync(new URL("../textures/sun.webp", import.meta.url)),
        // Earth
        tloader.loadAsync(new URL("../textures/2k_earth_daymap.webp", import.meta.url)),
        // background
        new CubeTextureLoader().loadAsync([
            new URL('../textures/starscape.webp', import.meta.url),
            new URL('../textures/starscape.webp', import.meta.url),
            new URL('../textures/empty.webp', import.meta.url),
            new URL('../textures/empty.webp', import.meta.url),
            new URL('../textures/starscape.webp', import.meta.url),
            new URL('../textures/starscape.webp', import.meta.url)
        ]),
    ]);
    return {
        dog: texs[0],
        sun: texs[1],
        earth: texs[2],
        background: texs[3]
    }
}



